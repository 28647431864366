<template>
  <div style="padding: 15px;background-color: #E2EEF7;">
    <q-input class="input-search" rounded outlined v-model="text" v-on:keyup.enter="onEnter">
      <template v-slot:prepend>
        <q-icon name="search" />
      </template>
      <template v-slot:append>
        <q-icon name="close" @click="onClear" class="cursor-pointer" />
      </template>
    </q-input>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const props = defineProps(['search'])
const emit = defineEmits(['onSearch'])

const text = ref('')

const onEnter = () => {
  emit('onSearch', text.value)
}

const onClear = () => {
  text.value = ''
  emit('onSearch', '')
}

</script>